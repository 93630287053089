// Insert your styling here.
.footer {
	background: lighten(@brand-primary, 5%);
	color: #fff;
	margin-top: 0px;
	padding-top: 0px;
	border-top: 0;
  a {
    color: #fff;
  }
	#block-block-1 {
		text-align: center;
		padding-top: 35px;
	}
	/* Kontaktuppgifter */
	#block-bean-kontaktuppgifter {
		background: @brand-info;
		.field {
			margin-bottom: 30px;
			text-transform: uppercase;
			&.field-name-title-field { text-transform: none;}
			a {
				color: #fff;
			}
			&.field-name-field-phone {
				display: inline-block;
				margin-right: 30px;
			}
			&.field-name-field-email {
				display:inline-block;
			}
		}
		.group-left,
		.group-right {
			@media (max-width: @screen-sm-min) {
				width: 100%;
			}
		}
		.group-left {
			color: #222222;
		}
		.group-right {
			color: #fff;
      padding: 30px 30px 0;
      @media (min-width: @screen-md-min) {
			  padding: 30px 60px 0;
      }
		}
		.field.field-name-field-social-links {
			.field-item {
				display: inline-block;
				margin-right: 15px;
			}
		}
		.facebook {
		  display:block;
		  font-size: 0px;
		  &:before {
			content: '';
			background: url('../images/Facebook-50.png') no-repeat;
			display:block;
			width: 50px;
			height: 50px;
		  }
	    }
		.instagram {
		  display:block;
		  font-size: 0px;
		  &:before {
			content: '';
			background: url('../images/Instagram-50.png') no-repeat;
			display:block;
			width: 50px;
			height: 50px;
		  }
	    }
	}
}