// Insert your styling here.

//dropdown
#navbar {
  margin-bottom: 0;
	ul.nav li.dropdown:hover ul.dropdown-menu {
		display: block;
	}
	ul.nav li.dropdown ul.dropdown-menu {
		margin-top: 0;
		@media (max-width: @grid-float-breakpoint){
			position:relative;
			width: 100%;
			margin-bottom: 15px;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
	}
}
#navbar {
	border-top: 7px solid @brand-primary;
}

@media (min-width: @grid-float-breakpoint){
  .navbar-nav{
    margin: 0 auto;
    display: table;
    table-layout: fixed;
    float:none;
    > li > a {
      padding-top: 33.5px;
      padding-bottom: 33.5px;
    }
  }
}

.navbar-brand {
  float:none;
  font-size: 37px;
  font-weight: bold;
  line-height: 1.4;
  color: @brand-primary !important;
}
p.lead {
  font-style:italic;
}

.menu.nav.navbar-nav {
	text-transform: uppercase;
	font-weight: bold;
}
.region.region-navigation {
	@media (min-width: @grid-float-breakpoint) {
		.pull-right();
		position: relative;
		top:-30px;
	}
	@media (min-width: @screen-md-min) {
		top: 0px;
	}
	
	.language-switcher-locale-url {
		text-align:right;
		list-style: none;
		li {
			list-style: none;
			display: inline;
			a {
				display:inline-block;
				height: 30px;
				width: 30px;
				line-height: 30px;
        font-size: 12px;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				border: 1px solid @brand-primary;
				border-radius: 50%;
				&:hover, &.active {
					background: @brand-primary;
					color: #fff;
          font-weight: bold;
				}
			}
		}
	}
}