// Insert your styling here.
.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.bluetext {
	color: @brand-primary;
}
#block-bean-kyocera {
  ul {
    list-style-type: disc;
    list-style-image: url(../images/diamond.png);
    list-style-position: inside;
    padding: 0;
    li {
      margin-bottom:10px;
      span {
        position:relative;
        top:-2px;
      }
    }
  }
}

// Insert your styling here.
.front {
  .btn.active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .main-container {
    .col-sm-12 {
      background: lighten(@brand-primary, 5%);
    }
    .page-header {
      color: #fff;
    }
    #block-system-main {
      color: #fff;
    }
  }
}

.front .page-header {
	border-bottom: 0;
	text-align: center;
  padding-top: 80px;
	color: #333333;
	font-weight: 100;
}
.page-header {
	border-bottom: 0;
	color: #333333;
	font-weight: 100;
}

.main-container {
	.block-title {
		text-align: center;
		margin-top: 80px;
		font-weight: 100;
	}
}
#page-header {
	.row();
}
.not-front #page-header {
	background: url('../images/header-bg.jpg?v=001') no-repeat;
	.cover();
  min-height: 300px;
  margin-bottom: 30px;
}
#block-system-main {
	margin-bottom: 80px;
	img {
		max-width: 100% !important;
		height: auto !important;
	}
}
/*h2.block-title {
    text-align: center;
    border-bottom: solid 1px;
    height: .6em; 
    
    margin-bottom: 1.4em;
  }


 h2.block-title:first-line{
    background-color: white;
  }*/
  
h2.block-title {
    overflow: hidden;
    text-align: center;
	line-height: 1.5;
  color: #474747;
}
h2.block-title:before,
h2.block-title:after {
    background-color: #e7e7e7;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
h2.block-title:before {
    right: 0.5em;
    margin-left: -50%;
}
h2.block-title:after {
    left: 0.5em;
    margin-right: -50%;
}
  
  

  

.node-type-site-contact-form #block-system-main {
	margin-bottom: 0;
}
#block-bean-slogan {
	h4 {
		letter-spacing: 4px;
	}
}
img {
	max-width: 100%;
	height: auto;
}
.btn {
	min-width: 190px;
	font-size: 13px;
  padding: 12px 15px;
}

.btn.btn-success,
.btn.btn-info {
	a {
		color: #fff;
		text-decoration: none;
		&:hover {
			color: #fff;
		}
	}
}
.btn.btn-primary {
	a {
		color: #aaaaaa;
		text-decoration: none;
		&:hover {
			color: #aaaaaa;
		}
	}
}

/* Frontpage blocks */
.view.view-frontpage-blocks {
  .node.node-frontpage-blocks {
    height: 300px;
    margin-bottom: 80px;
  }
  .field.field-name-field-text {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1.6;
  }
	.field.field-name-field-service-category {
		margin: 0 auto;
		position: relative; 
		height: 100px; 
		width: 100px; 
		border-radius:10px;
		background-color: @brand-primary; 
		-webkit-transform: rotate(45deg); 
		transform: rotate(45deg);
		> div {
			-webkit-transform: rotate(-45deg); 
			transform: rotate(-45deg);
		}
		color:#fff;
		font-size: 24px;
		line-height: 100px;
		.taxonomy-term-description {
			text-align:center;
		}
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.field.field-name-title-field {
		text-align:center;
    h4 {
      color: #787878;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.1em;
      line-height: 1.5;
      font-weight: 600;
    }
	}
	.field.field-name-body {
		text-align:center;
	}
}
#block-bean-vi-gor-dina-inkop-lattare {
	.content {
		text-align:center;
		padding-bottom: 30px;
	}
	.block-title {
		text-align: center;
		margin-top: 80px;
		font-weight: 100;
	}
}
/* Aktuellt startsida */
.view.view-pool-news {
	padding-bottom: 30px;
	.ds-1col {
		margin-bottom: 15px;
		&:hover {
			.field.field-name-date .day {
				background: @brand-primary;
			}
			.field.field-name-title-field {
				color: @brand-primary;
				a {
					color: @brand-primary;
				}
			}
		}
	}
	.field.field-name-date {
		font-family: 'Lato', sans-serif;
		display: inline-block;
		text-align: center;
		margin-right: 15px;
		vertical-align: top;
		.day {
			background: #a3bb96;
			color: #fff;
			font-size: 40px;
			padding: 15px 25px;
		}
		.month {
			background: #026664;
			color: #fff;
			text-transform: capitalize;
			padding: 5px 25px;
		}
	}
	.field.field-name-title-field {
		color: #333333;
		h4 {
			font-family: 'Lato', sans-serif;
			font-weight: bold;
			font-size: 18px;
		}
		a {
			color: #333333;
			text-decoration: none;
		}
	}
	.field.field-name-body {
		font-family: 'Lato', sans-serif;
		font-size: 14px;
		border-bottom: 1px solid #f3f3f3;
		padding-bottom: 5px;
		margin-bottom: 5px;
		color: #a1b1bc;
	}	
	.group-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 78%;
		h4 {
			margin-top: 0;
		}
	}
}

#block-mailchimp-signup-newsletter,
#block-simplenews-0 {
	background: url('../images/newsletter-bg.jpg') no-repeat;
	.cover();
	padding-bottom: 100px;
	.block-title {
		color: #fff;
		&:before, &:after {
			content: none;
		}
	}
	p {
		color: #fff;
		text-align:center;
		font-size: 19px;
		font-weight: 300;
		padding-left: 10%;
		padding-right: 10%;
	}
	#simplenews-block-form-7 {
		text-align:center;
		margin-top: 60px;
	}
	.form-type-textfield {
		@media (min-width: @screen-sm-min){
			width: 30%;
		}
		display: inline-block;
		vertical-align: top;
		text-align:left;
		label {
			text-indent: -9999px;
			position: absolute;
		}
	}
	#edit-submit {
		display: inline-block;
		vertical-align: top;
	}
	#edit-mail {
		display: inline-block;
	}
	.radio label, .checkbox label {
		padding-left: 0;
	}
  #mailchimp-signup-subscribe-block-newsletter-form {
    .container();
    > div {
      color: #fff;
      margin-bottom: 60px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      line-height: 1.6;
    }
    .form-control {
      height: 44px;
      vertical-align: top;
    }
    .btn {
      vertical-align: top;
    }
  }
  .mailchimp-newsletter-mergefields {
    display: inline-block;
  }
  #mailchimp-signup-subscribe-block-newsletter-form,
	#simplenews-subscriptions-multi-block-form {
		text-align: center;
		#edit-newsletters {
			text-align: left;
			display:table;
			margin:15px auto 30px;
			color: #fff;
		}
	}
	.btn-default, .btn-primary {
		color: #fff;
		border-color: #fff;
	}
	
}

.flexslider {
	background: transparent;
	border-radius: 0;
	border: 0 none;
	.box-shadow(0);
	.slides img {
		width: 100%;
		height: auto;
	}
}

.flex-direction-nav a {
	width: 50px;
	height: 50px;
	background: @brand-primary;
	border-radius: 50%;
	text-align: center;
	line-height: 50px;
	font-size: 0px;
	color: #fff;
	text-shadow: 0;
	&:before {
		color: #fff;
		text-shadow: 0;
		font-size: 30px;
	}
}
.flex-direction-nav {
	.flex-next {
		text-align: center;
	}
	a {
		&:before {
			font-family: FontAwesome;
			content: @fa-var-angle-left;
		}
		&.flex-next:before {
			font-family: FontAwesome;
			content: @fa-var-angle-right;
		}
	}
}

/* Header block */

#block-block-3 {
  padding-top: 30px;
  padding-bottom: 30px;
   @media (min-width: @screen-sm-min) {
     padding-top: 80px;
    padding-bottom: 40px;
  }
	p {color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
    margin: 0;
  }
	h2 {
		font-size: 39px;
		font-weight: bold;
		margin-top: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
	}
  @media (max-width: @screen-sm-min) {
    .pull-left {
      float: none !important;
    }
  }
  @media (min-width: @screen-sm-min) {
    .pull-left {
      position:relative;
      top: -20px;
      margin-right: 20px;
    }
    ;
  }
  @media (min-width: @screen-md-min) {
  .pull-left {
    position:relative;
    top: -20px;
    margin-right: 85px;
  }
}
}

/* Frontpage slideshow */
#block-views-frontpage-slideshow-block {
	color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
	.flexslider {
		position: relative;
		margin: 0;
		overflow: hidden;
		.slides {
      .field-name-field-image {
        position:relative;
        &:before {
          background-color: rgba(0, 0, 0, 0.3);
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
			img {
				width: 100%;
				height: auto;
			}
		}
		.flex-control-paging li a {
			text-indent: 0;
			background: transparent;
      border: 1px solid #fff;
      border-radius: 50%;
			width: 25px;
			height: 25px;
			line-height: 25px;
			color: #fff;
			text-decoration: none;
			&.flex-active {
				color: #fff;
        -webkit-text-shadow: none;
        -moz-text-shadow: none;
        text-shadow: none;
				background: @brand-primary;
        border-color: @brand-primary;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
			}
		}
		-webkit-transform-style: preserve-3d;
		  -moz-transform-style: preserve-3d;
		  transform-style: preserve-3d;
		.flex-caption {
      .container();
			position: absolute;
			top: 50%;
			left: 0%;
			right: 0%;
			padding-left: 15px;
			padding-right: 15px;
			-webkit-transform: translate(-0%, -50%);
			-ms-transform: translate(-0%, -50%);
			transform: translate(-0%, -50%);
      text-align:center;
			h2 {
				font-size: 69px;
				font-weight: bold;
				margin-top: 0;
			}
			@media (max-width: @screen-md-min) {
				.field.field-name-body {
					//display: none;
          h2 {
            font-size: 29px;
          }
				}
      } 
      @media (max-width: @screen-sm-min) {
			  .btn {
				  display: none;
        }
      }
			.field.field-name-body {
				margin-bottom: 30px;
			}
			.field.field-name-title-field h2 {
				a {
					text-decoration: none;
					color: #fff;
				}
			}
      .field.field-name-field-link,
      .field.field-name-field-link2 {
        display: inline-block;
        text-shadow: none;
      }
		}
		.flex-control-nav {
      @media (min-width: @screen-sm-min) {
			  bottom: 5%;
      }
			z-index: 10;
		}
	}
	.btn {
		margin-right: 10px;
		margin-bottom: 10px;
    text-shadow: none;
    border-radius: 5px;
    text-transform: uppercase;
	}
}
/* Reference slideshow */
#block-views-reference-slideshow-block {
	background: #f9fafb;
	color: #b7c3cc;
	padding-top: 80px;
	.flexslider {
		.slides {
			.container();
			img {
				border-radius: 50%;
				margin: 0 auto;
			}
		}
	}
	.rteindent4 {
		margin-left: 70%;
	}
}

/* Projects */

#block-views-projects-block {
	text-align: center;
	padding-bottom: 80px;
	.field.field-name-field-teaser-image {
		text-align: center;
		img {
			border: 3px solid @brand-primary;
			border-radius: 50%;
			margin: 0 auto;
		}
	}
	.view.view-projects {
		.view-header {
			margin-bottom: 80px;
		}
	}
	h4 {
		color: #414141;
		a {
			color: #414141;
			text-decoration: none;
		}
	}
}

/* Facebook feed  */
.carousel li {
  margin-right: 0;	
}	
#block-views-facebook-feed-block {
  padding-bottom: 30px;
	.block-title {
		&:before, &:after {
			content: none;
		}
    color: #fff;
	}
	background: @brand-primary url('../images/facebook.png') no-repeat;
	background-position: right 5% bottom -70%;
	
	.view.view-facebook-feed {
		.views-row {
			a {
				color: #fff;
				text-decoration: none;
			}
			&:hover {
				color: #026664;
				a {
					color: #026664;
				}
			}
		}
    .field.field-name-field-text {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      line-height: 1.6;
    }
		.view-content .flexslider {
	  	  .flex-viewport {
	  		  /*height: 356px;*/
	  		  max-width: 1140px;
	  		  margin: 0 auto;
	      }
		  .flex-direction-nav a {
			  &:before {
				  font-size: 48px;
				  font-weight: bold;
			  }
		  }
	 	 .slides > li {
	 	   > div {
			   background: #fff;
	 		   /*padding-left: 15px;
	 		   padding-right: 15px;*/
			   margin-right: 5px;
			   margin-left: 5px;
			   .field.field-name-field-image {
				   .field-item {
					   margin: 10px;
					   border: 1px solid #dadada;
					   text-align: center;
					   height: 220px;
					   line-height: 215px;
					   img {
						   max-width: 100%;
						   width: auto;
						   height: auto;
						   margin: auto;
						   max-height: 215px;
						   line-height: 0;
						   display: inline-block;
						   vertical-align: middle;
					   }
				   }
			   }
			   .group-fb-link {
				   text-decoration: none;
				   color: #969595;
				   display: block;
				   height: 372px;
			   }
			   .field.field-name-post-date {
				   padding-left: 30px;
				   padding-right: 30px;
			   }
			   .field.field-name-body {
				   padding-left: 30px;
				   padding-right: 30px;
				   padding-top: 15px;
				   padding-bottom: 15px;
			   }
			   .field-group-format.group_wrapper {
				   background: #f5f5f5;
				   color: #969595;
				   padding: 10px 15px;
				   font-size: 12px;
				   .field.field-name-field-comments-count {
					  display: inline-block;
					  margin-right: 10px;
					  background: #fff;
					  padding: 10px 20px;
					  border-radius: 20px;
					  .field-item:before {
			  			font-family: FontAwesome;
			  			content: @fa-var-comment;
						margin-right: 5px;
						color: #dadada; 
					  }
				   }
				   .field.field-name-field-likes-count {
				   	  display: inline-block;
					  background: #fff;
					  padding: 10px 20px;
					  border-radius: 20px;
					  .field-item:before {
			  			font-family: FontAwesome;
			  			content: @fa-var-heart;
						margin-right: 5px;
						color: #dadada; 
					  }
				   }
				   .field.field-name-field-link {
				   	  display: inline-block;
					  float: right;
					  background: #fff;
					  padding: 10px 20px;
					  border-radius: 20px;
					  .field-item:before {
			  			font-family: FontAwesome;
			  			content: @fa-var-share;
						margin-right: 5px;
						color: #dadada; 
					  }
				   }
			   }
	 	   }
	 	 }
		}
	}
	
	.view-footer {
		.block.block-bean {
			margin: 15px 0 30px;
		}
	}
	.block.block-bean {
		text-align: center;
	}
}


#block-bean-facebook-title {
	color: #fff;
	.block-title {
		margin-top: 80px;
	}
	margin-bottom: 30px;
}

/* Contact page */
.front .entityform.entitytype-contact_form-form {
 @media (min-width: @screen-sm-min){
	  #edit-field-name {
		  width: 50%;
		  float: left;
		  padding-right: 15px;
	  }
	  #edit-field-email {
		  width: 50%;
		  float: left;
		  padding-left: 15px;
	  }
	  #edit-field-message {
		  clear: both;
	  }
  }
  .form-actions.form-wrapper.form-group {
	  text-align: center;
	  margin-bottom: 30px;
  }
}
.entityform.entitytype-contact_form-form {
    .grippie {
  	  display: none;
    }
}
/* Contact block */
#block-bean-contact-block {
  padding-bottom: 60px;
	.form-control {
		border-color: #f0f0f0;
	}
	background: url('../images/contact-pattern.png');
	> * {
		max-width: 1140px;
		margin-left: auto;
		margin-right: auto;
	}
	.content {
		max-width: 780px;
		padding-left: 15px;
		padding-right: 15px;
		margin-left: auto;
		margin-right: auto;
		.field.field-name-field-text {
			padding-bottom: 30px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
		}
		.field-type-text-long.field-name-field-message {
			margin-bottom: 30px;
		}
	}
}
textarea, input[type="text"] {
  -webkit-appearance: none;
}
.form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  &:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

/* Employees */

.view.view-employees {
  .view-header {
    text-transform: uppercase;
  }
  .view-content .col {
	  margin-bottom: 30px;
	  .clearfix();
  }
}

/* Featured products */
#block-bean-vara-erbjudanden {
	.content {
		text-align:center;
		padding-bottom: 30px;
	}
	.block-title {
		text-align: center;
		margin-top: 80px;
		font-weight: 100;
	}
  .field.field-name-field-text {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1.6;
  }
}
.view.view-featured-product {
	.view-content {
		.col {
			margin-bottom: 30px;
			position: relative;
			-webkit-transform-style: preserve-3d;
			  -moz-transform-style: preserve-3d;
			  transform-style: preserve-3d;
			&:before {
				content: @fa-var-search;
				padding: 20px;
				border-radius: 50%;
				background: @brand-primary;
				font: normal normal normal 14px/1 FontAwesome;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transform: translate(0, 0);
				color: #fff;
				font-size: 24px;
				position: absolute;
				left: 0;
				right: 0;
				top: 40%;
				margin: 0 auto;
				display: inline-block;
				width: 64px;
			    transform: translateY(-40%);
				opacity: 0;
				z-index: 10;
			    transition: opacity .25s ease-in-out;
			     -moz-transition: opacity .25s ease-in-out;
			     -webkit-transition: opacity .25s ease-in-out;
			}
			&:hover {
				&:before {
					opacity: 1;
				}
				cursor: pointer;
				> div {
				  opacity: 0.6;
				}
			}
			> div {
				opacity: 1;
			    transition: opacity .25s ease-in-out;
			     -moz-transition: opacity .25s ease-in-out;
			     -webkit-transition: opacity .25s ease-in-out;
			}
			.field-group-format.group_wrapper {
				height: 190px;
				margin-bottom: 0;
				border-top: 0 none;
			}
			text-align: center;
			.field.field-name-title-field {
				h5 {
					text-transform: uppercase;
					font-weight: 700;
				}
				
			}
			.field.field-name-body {
				font-size: 13px;
				min-height: 54px;
			}
			.field.field-name-field-images {
				border-left: 1px solid;
				border-right: 1px solid;
				border-top: 1px solid;
				height: 262px;
				border-bottom: 0 none;
				border-color: #eee;
				width: 100%;
				padding: 15px;
				display:table;
				.field-items {
					display:table-cell;
					vertical-align: middle;
				}
			  	img {
				  max-width: 100%;
				  height: auto;
				}
			}
			.field.field-name-commerce-price {
				color: @brand-primary;
				margin-top: 30px;
				font-size: 20px;
			}
		}
	}
}

/* Kyocera block */

#block-bean-kyocera {
	.block-title {
		&:before, &:after {
			content: none;
		}
		text-align: left;
	}
	background: #f8f8f8;
	padding-bottom: 30px;
	.entity.entity-bean {
		.container();
    .field-name-field-text {
      > .field-items > .field-item > div {
        -webkit-column-count: 2; 
        -moz-column-count: 2;
        column-count: 2;
      }
    }
		.field.field-name-field-image img {
			margin-top: 100px;
		}
		.btn.btn-primary {
			margin-top: 30px;
			margin-bottom: 30px;
		}
	}
}

#block-bean-verkkotunnus {
  background-color: #e7e7e7;
  text-align: center;
  .entity {
    .container();
    padding-top:30px;
    padding-bottom:30px;
    .btn-primary {
      border-radius: 5px;
      background-color: lighten(@brand-primary, 5%);
      border-color: lighten(@brand-primary, 5%);
      color: #fff;
      text-transform: uppercase;
    }
  }
}


/* Gallery */

.node-image-gallery > .row > div > div {
	margin-bottom: 30px;
}


/* Kontaktuppgifter */
#block-bean-kontaktuppgifter {
	background: @brand-info;
	color: #fff;
	.field {
		margin-bottom: 30px;
		text-transform: uppercase;
		&.field-name-title-field { text-transform: none;}
		a {
			color: #fff;
		}
    &.field-name-field-text {
      p {
        letter-spacing: 0.1em;
      }
      strong {
        letter-spacing: normal;
      }
    }
		&.field-name-field-phone {
			display: inline-block;
			margin-right: 30px;
      .field-items {
        letter-spacing: 0.1em;
      }
		}
		&.field-name-field-email {
			display:inline-block;
      .field-items {
        letter-spacing: 0.1em;
      }
		}
	}
	.group-left,
	.group-right {
		@media (max-width: @screen-sm-min) {
			width: 100%;
		}
	}
	.group-right {
		padding: 30px;
	}
}

.navbar-default .navbar-nav > li > a {
	&:focus {
	outline: 0;
	}
}
/* Block menu catalog */
.region.region-navigation #block-menu-menu-catalog {
	@media (min-width: @grid-float-breakpoint) {
		display: none;
	}
	.nav {
		.row();
		font-weight: bold;
	}
	.nav > li > a {
		color: #959595;
		text-transform: uppercase;
		&:focus {
			outline: 0;
		}
	}
	.dropdown-menu > li > a {
		color: #aeaeae;
		&:focus {
		outline: 0;
		}
	}
	ul.nav li.dropdown ul.dropdown-menu {
	  margin-top: 0;
	  position:relative;
	  width: 100%;
	  margin-bottom: 15px;
	  background-color: transparent;
	  border: 0 none;
	  -webkit-box-shadow: none;
	  -moz-box-shadow: none;
	  box-shadow: none;
	  > li > a {
		  padding: 12px 30px;
	  }
	}
	.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		background-color: transparent;
	}
	.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
		background-color: transparent;
	}
	.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		color: #03b1ed;
	}
	.nav > li > a:hover, .nav > li > a:focus {
		color: #03b1ed;
	}
}
#block-menu-menu-catalog--2 {
	@media (max-width: @grid-float-breakpoint) {
		display:none;
	}
	.nav {
		font-size: 13px;
		font-weight: bold;
	}
	//dropdown
	ul.nav li.expanded.active-trail ul.dropdown-menu {
	  display: block;
	}
	ul.nav li.dropdown ul.dropdown-menu {
	  margin-top: 0;
	  position:relative;
	  width: 100%;
	  margin-bottom: 15px;
	  background-color: transparent;
	  border: 0 none;
	  -webkit-box-shadow: none;
	  -moz-box-shadow: none;
	  box-shadow: none;
	  > li > a {
		  padding: 12px 30px;
	  }
	}
	.nav > li > a {
		color: #757575;
		text-transform: uppercase;
		&:focus {
			outline: 0;
		}
	}
	.dropdown-menu > li > a {
		color: #aeaeae;
		&:focus {
			outline: 0;
		}
	}
	.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		color: #03b1ed;
	}
	.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		background-color: transparent;
	}
	.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
		background-color: transparent;
	}
	.nav > li > a:hover, .nav > li > a:focus {
		color: #03b1ed;
	}
}

/* Employees */
.view.view-employees {
	padding-top: 0;
	margin-bottom: 30px;
	.view-header {
		text-align: center;
		padding-bottom: 30px;
    letter-spacing: 0.1em;
    line-height: 1.6;
	}
	.field.field-name-title h5 {
		text-transform: uppercase;
		font-weight: bold;
	}
	.field.field-name-body {
		padding-left: 15px;
	}
	.field.field-name-field-image {
		text-align: center;
		img {
			border-radius: 50%;
		}
	}
	.group-left {width: 40%; }
	.group-right {width: 60%; }
}


/* Product catalog */

.view.view-product-catalog,
.view.view-product-categories-catalog {
	.view-content{
		.col {
			&:before {
				content: @fa-var-search;
				padding: 20px;
				border-radius: 50%;
				background: @brand-primary;
				font: normal normal normal 14px/1 FontAwesome;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transform: translate(0, 0);
				color: #fff;
				font-size: 24px;
				position: absolute;
				left: 0;
				right: 0;
				top: 30%;
				margin: 0 auto;
				display: inline-block;
				width: 64px;
			    transform: translateY(-30%);
				opacity: 0;
				z-index: 10;
			    transition: opacity .25s ease-in-out;
			     -moz-transition: opacity .25s ease-in-out;
			     -webkit-transition: opacity .25s ease-in-out;
			}
			&:hover {
				cursor: pointer;
				&:before {
					opacity: 1;
				}
			  > div {
				  opacity: 0.6;
				-webkit-box-shadow: 0px 0px 5px 0px rgba(187,187,187,1);
				-moz-box-shadow: 0px 0px 5px 0px rgba(187,187,187,1);
				box-shadow: 0px 0px 5px 0px rgba(187,187,187,1);
				tr.commerce-price-savings-formatter-price {
					.price-amount {
						color: @brand-primary;
					}
				}
				.field.field-name-title {
					color: @brand-primary;
					a {
						color: @brand-primary;
					}
				}
			  }
			} 
			margin-bottom: 30px;
		  > div {
			 opacity: 1;
		     transition: opacity .25s ease-in-out;
		     -moz-transition: opacity .25s ease-in-out;
		     -webkit-transition: opacity .25s ease-in-out;
			border: 1px solid #efefef;
      padding-bottom: 10px;
			.field.field-name-field-images {
				height: 150px;
				text-align: center;
				margin-bottom: 60px;
			}
		  }
		  .field.field-name-commerce-price {
			  height: 65px;
		  }
		  .field.field-name-title {
			  padding-left: 15px;
			  padding-right: 15px;
			  color: #787878;
			  font-size: 13px;
			  font-weight: 700;
			  a {
			  	color: #787878;
				text-decoration: none;
			  }
		  }
		}
		.table {
			margin-bottom: 0;
			&.commerce-price-savings-formatter-prices {
				float:right;
				margin-right: 15px;
			}
		}
		tr.commerce-price-savings-formatter-list {
			background-color: transparent;
			.price-amount {
				border: 0 none;
				font-size: 12px;
				padding: 0;
			}
		}
		tr.commerce-price-savings-formatter-price {
			background-color: transparent;
			.price-amount {
				border: 0 none;
				padding: 0;
				color: #787878;
				font-weight: 700;
			}
		}
	}
}

/* Product node */

.node.node-product {
	.field.field-name-title-field {
		color: @brand-primary;
		h1 {
			font-size: 24px;
      margin-top: 0;
		}
	}
	.table {
		margin-bottom: 0;
		&.commerce-price-savings-formatter-prices {
			margin-right: 15px;
		}
	}
	tr.commerce-price-savings-formatter-list {
		background-color: transparent;
		.price-amount {
			border: 0 none;
			font-size: 15px;
			padding: 0;
		}
	}
	tr.commerce-price-savings-formatter-price {
		.price-amount {
			border: 0 none;
			padding: 0;
			font-size: 18px;
			color: #787878;
			font-weight: 700;
		}
	}
}

/* Pager */
.pager.pager-load-more {
	li {
		a {
			display: block;
			background: url('../images/Plus-50.png') no-repeat;
			width: 50px;
			height: 50px;
			font-size: 0px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}